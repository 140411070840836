import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow } from "swiper/modules";

import { isMobileDevice } from "../../utils";
import { useViewport } from "../viewportContext";
import MoveFadeIn from "./MoveFadeIn";

export default function Home() {
  const { width } = useViewport();

  const [selectKey, setSelectKey] = useState(0);

  return (
    <div className="advantage advantage-bg" id="Technology">
      <MoveFadeIn>
        <div className="w-1140">
          <div className="advantage-title">
            Why <span>UniAgent?</span>
          </div>
          <div className="advantage-subtitle">
            Empowering You to Own, Control, and Monetize Your AI with Our
            Blockchain Platform.
          </div>
          <div className="advantageContent">
            {width >= 1024 && <div className="advantageContent-top"></div>}
            <div className="advantageContent-bottom">
              {[
                {
                  id: 1,
                  title: "Sovereignty and Privacy",
                  content:
                    "Maintain complete control over your AI assets while protecting user data and models with advanced privacy-preserving technologies.",
                },
                {
                  id: 2,
                  title: "Transparency",
                  content:
                    "Guarantee full traceability of data and model interactions, ensuring trust, scalability, and accountability with blockchain.",
                },
                {
                  id: 3,
                  title: "Collaborative Economy",
                  content:
                    "Foster broad participation and fair wealth distribution through an open economic model and transparent DAO governance.",
                },
                {
                  id: 4,
                  title: "Equitable Monetization",
                  content:
                    "Ensure fair revenue sharing and reward contributions transparently through a secure blockchain system.",
                },
              ].map((item: any, index: any) => (
                <div className="advantageContent-item" key={index}>
                  {width >= 1024 && <div className="advantageContent-r"></div>}
                  <div className="advantageContent-box">
                    <div>{item?.id}</div>
                    <span className="title">{item?.title}</span>
                    {item?.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </MoveFadeIn>
    </div>
  );
}
