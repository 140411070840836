import { useNavigate } from "react-router-dom";
import logoIcon from "../../../assets/image/logo.png";
import dropDownIcon from "../../../assets/image/dropDownIcon.png";
import languageIcon from "../../../assets/image/languageIcon.png";
import hotIcon from "../../../assets/image/hotIcon.png";
import c_img1 from "../../../assets/image/menuIcon/c_img1.svg";
import c_img2 from "../../../assets/image/menuIcon/c_img2.svg";
import c_img4 from "../../../assets/image/menuIcon/c_img4.svg";
import c_img5 from "../../../assets/image/menuIcon/c_img5.svg";
import c_img_active1 from "../../../assets/image/menuIcon/c_img_active1.svg";
import c_img_active2 from "../../../assets/image/menuIcon/c_img_active2.svg";
import c_img_active4 from "../../../assets/image/menuIcon/c_img_active4.svg";
import c_img_active5 from "../../../assets/image/menuIcon/c_img_active5.svg";

import r_img_active1 from "../../../assets/image/rMenuIcon/r_img_active1.svg";
import r_img_active2 from "../../../assets/image/rMenuIcon/r_img_active2.svg";
import r_img_active3 from "../../../assets/image/rMenuIcon/r_img_active3.svg";
import r_img_active4 from "../../../assets/image/rMenuIcon/r_img_active4.svg";
import r_img_active5 from "../../../assets/image/rMenuIcon/r_img_active5.svg";
import r_img_active6 from "../../../assets/image/rMenuIcon/r_img_active6.svg";
import r_img1 from "../../../assets/image/rMenuIcon/r_img1.svg";
import r_img2 from "../../../assets/image/rMenuIcon/r_img2.svg";
import r_img3 from "../../../assets/image/rMenuIcon/r_img3.svg";
import r_img4 from "../../../assets/image/rMenuIcon/r_img4.svg";
import r_img5 from "../../../assets/image/rMenuIcon/r_img5.svg";
import r_img6 from "../../../assets/image/rMenuIcon/r_img6.svg";
import en_icon from "../../../assets/image/en_icon.svg";
import en_icon_active from "../../../assets/image/en_icon_active.svg";
import { scrollToAnchor } from "../../../utils";
import { useTranslation } from "react-i18next";

import { Dropdown, Menu, notification } from "antd";
export const linkBuyNFT = "https://nft.uniagent.co/";
export const linkObj = {
  tw: "https://x.com/Uni_AIAgents",
  tg: "https://t.me/+AzumnD3mpkQ2M2Rl",
  md: "https://medium.com/@UniAgent",
  WhitePaper: "https://www.uniagent.co/pdf/Whitepaper.pdf",
  Gitbook: "https://uniagent.gitbook.io/uniagent",
  Plan: "https://www.uniagent.co/pdf/ProjectIntroduction.pdf",
  DeAI: "https://www.uniagent.co/pdf/DeAILightingPlan.pdf",
  LitePaper: "",
  Model: "",
};

export default function Home() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const LOCAL_KEY = "MBAS_LANG";
  let communityObj = [
    {
      value: "Telegram Community",
      key: "c_item1",
      img: c_img1,
      activeImg: c_img_active1,
      link: "https://t.me/+AzumnD3mpkQ2M2Rl",
    },
    {
      value: "Twitter",
      key: "c_item2",
      img: c_img2,
      activeImg: c_img_active2,
      link: "https://x.com/Uni_AIAgents",
    },
    {
      value: "Telegram Channel",
      key: "c_item3",
      img: c_img1,
      activeImg: c_img_active1,
      link: "https://t.me/UniAgent_AI",
    },
    {
      value: "Medium",
      key: "c_item4",
      img: c_img4,
      activeImg: c_img_active4,
      link: "https://medium.com/@UniAgent",
    },
    {
      value: "Youtube",
      key: "c_item5",
      img: c_img5,
      activeImg: c_img_active5,
      link: "https://www.youtube.com/@UniAgent",
    },
    // { value: "中文繁體", key: "zh" },
  ];
  let resourceObj = [
    {
      value: "WhitePaper",
      key: "r_item1",
      img: r_img1,
      activeImg: r_img_active1,
      link: "https://www.uniagent.co/pdf/Whitepaper.pdf",
    },
    {
      value: "Gitbook",
      key: "r_item2",
      img: r_img2,
      activeImg: r_img_active2,
      link: "https://uniagent.gitbook.io/uniagent",
    },
    {
      value: "Business Plan",
      key: "r_item3",
      img: r_img3,
      activeImg: r_img_active3,
      link: "https://www.uniagent.co/pdf/ProjectIntroduction.pdf",
    },
    {
      value: "DeAI Lighting Plan",
      key: "r_item3",
      img: r_img6,
      activeImg: r_img_active6,
      link: "https://www.uniagent.co/pdf/DeAILightingPlan.pdf",
    },
    {
      value: "LitePaper",
      key: "r_item4",
      img: r_img4,
      activeImg: r_img_active4,
      link: "",
    },
    {
      value: "Economic Model",
      key: "r_item5",
      img: r_img5,
      activeImg: r_img_active5,
      link: "",
    },
    // { value: "中文繁體", key: "zh" },
  ];
  let langObj = [
    { value: "English", key: "en", img: en_icon, activeImg: en_icon_active },
    // { value: "简体中文", key: "zh" },
    // { value: "日本語", key: "ja" },
    // { value: "中文繁體", key: "zh" },
  ];
  function changeLanguage() {
    const key = i18n.language;
    window.localStorage.setItem(LOCAL_KEY, key);
    i18n.changeLanguage(key);
  }

  const menu1 = (
    <Menu
      onClick={() => {}}
      items={communityObj.map((item: any) => {
        return {
          label: (
            <span
              className="LangItem"
              onClick={() => {
                window.open(item?.link);
              }}
            >
              <img className="img" src={item?.img} alt="" />
              <img className="activeImg" src={item?.activeImg} alt="" />{" "}
              {item.value}
            </span>
          ),
          key: item?.key,
        };
      })}
    />
  );
  const menu2 = (
    <Menu
      onClick={() => {}}
      items={resourceObj.map((item: any) => {
        return {
          label: (
            <span
              className="LangItem"
              onClick={() => {
                if (!!item?.link) {
                  window.open(item?.link);
                } else {
                  return notification.open({
                    key: "500",
                    message: "Coming SOON",
                  });
                }
              }}
            >
              <img className="img" src={item?.img} alt="" />
              <img className="activeImg" src={item?.activeImg} alt="" />{" "}
              {item.value}
            </span>
          ),
          key: item?.key,
        };
      })}
    />
  );
  const menu3 = (
    <Menu
      onClick={changeLanguage}
      items={langObj.map((item: any) => {
        return {
          label: (
            <span className="LangItem">
              <img className="img" src={item?.img} alt="" />
              <img className="activeImg" src={item?.activeImg} alt="" />{" "}
              {item.value}
            </span>
          ),
          key: item?.key,
        };
      })}
    />
  );
  return (
    <div className="pc-container">
      <div className="pc-header">
        <img src={logoIcon} className="logoIcon" alt="" />
        <div className="nav">
          <div
            className="active nav-item"
            onClick={() => {
              scrollToAnchor("Homepage");
            }}
          >
            Home
          </div>
          <div
            className="nav-item nav-item-hot"
            onClick={() => {
              window.open(linkBuyNFT);
              // return notification.open({
              //   key: "500",
              //   message: "Coming SOON",
              // });
            }}
          >
            <img src={hotIcon} alt="" /> UniAgent AI Node Key
          </div>
          <div
            className="nav-item"
            onClick={() => {
              scrollToAnchor("Partners");
            }}
          >
            About
          </div>
          <div
            className="nav-item"
            onClick={() => {
              scrollToAnchor("Technology");
            }}
          >
            Technology
          </div>

          <div
            className="nav-item"
            onClick={() => {
              scrollToAnchor("UAC");
            }}
          >
            Super $UAC
          </div>
          <div
            className="nav-item"
            onClick={() => {
              scrollToAnchor("Foundation");
            }}
          >
            Foundation
          </div>
          <div
            className="nav-item"
            onClick={() => {
              scrollToAnchor("Vanta_Labs");
            }}
          >
            NexAI Labs
          </div>
        </div>

        {/* <div
        className='dashboard'
        onClick={() => {
          navigate('dashboard');
        }}>
        Dashboard
      </div> */}
      </div>
      <div className="pc-manage">
        <Dropdown
          overlay={menu1}
          placement="bottom"
          overlayClassName="LangDropDown"
          trigger={["hover"]}
          arrow={true}
          getPopupContainer={(triggerNode: any) => triggerNode}
        >
          <div className="dropdownBox">
            Community <img src={dropDownIcon} alt="" className="langIcon" />
          </div>
        </Dropdown>
        <Dropdown
          overlay={menu2}
          placement="bottom"
          overlayClassName="LangDropDown"
          trigger={["hover"]}
          arrow={true}
          getPopupContainer={(triggerNode: any) => triggerNode}
        >
          <div className="dropdownBox resourcesDropdownBox">
            Resources <img src={dropDownIcon} alt="" className="langIcon" />
          </div>
        </Dropdown>
        <Dropdown
          overlay={menu3}
          placement="bottom"
          overlayClassName="LangDropDown"
          trigger={["hover"]}
          arrow={true}
          getPopupContainer={(triggerNode: any) => triggerNode}
        >
          <div className="langDrowDrop">
            <img src={languageIcon} alt="" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
}
