//@ts-nocheck
import lab_video from "../../assets/video/lab_video.gif";
// import lab_video from "../../assets/image/lab_video.png";
import { useViewport } from "../viewportContext";
// import lab_video from "../../assets/video/lab_video.mp4";
import MoveFadeIn from "./MoveFadeIn.tsx";

export default function Home() {
  const { width } = useViewport();
  return (
    <div className="labs" id="Vanta_Labs">
      <MoveFadeIn>
        <div className="labs-box w-1140">
          <div className="labs-left">
            {width > 650 ? (
              <div className="labs-left-p">
                <span>NexAI Labs</span> - The R&D Lab Behind <br /> UniAgent
              </div>
            ) : (
              <div className="labs-left-p">
                <span>NexAI Labs</span> - The R&D Lab Behind UniAgent
              </div>
            )}
            <div className="labs-left-text">
              NexAI Labs, with over 20 experts from top companies like Google,
              Microsoft, and Polkadot, leads the development of UniAgent1.0,
              2.0, and 3.0, bringing expertise in AI, machine learning, and
              blockchain engineering.
            </div>
          </div>
          <div className="labs-right">
            {/* <video className="video" autoPlay loop muted playsInline>
              <source src={lab_video} type="video/mp4" />
            </video> */}
            <img src={lab_video} className="video" alt="" />
          </div>
        </div>
      </MoveFadeIn>
    </div>
  );
}
