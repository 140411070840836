import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import innovativeContent_item1 from "../../assets/image/innovativeContent-item1.png";
import innovativeContent_item2 from "../../assets/image/innovativeContent-item2.png";
import innovativeContent_item3 from "../../assets/image/innovativeContent-item3.png";
import innovativeContent_item4 from "../../assets/image/innovativeContent-item4.png";
import innovativeContent_item5 from "../../assets/image/innovativeContent-item5.png";
import innovativeContent_item6 from "../../assets/image/innovativeContent-item6.png";
// import required modules
import { EffectCoverflow } from "swiper/modules";

import { isMobileDevice } from "../../utils";
import { useViewport } from "../viewportContext";
import MoveFadeIn from "./MoveFadeIn";

export default function Home() {
  const { width } = useViewport();

  const [selectKey, setSelectKey] = useState(0);

  return (
    <div className="innovative innovative-bg" id="Economy">
      <div className="w-1140">
        <MoveFadeIn>
          <div className="innovative-title">
            Participate In The <span>AI Agentic Revolution</span>
          </div>
        </MoveFadeIn>
        <MoveFadeIn>
          <div className="innovativeContent">
            {[
              {
                id: 1,
                subtitle: "Developers",
                img: innovativeContent_item1,
                content:
                  "Build, manage, and monetize sophisticated AI agents in a decentralized marketplace. End Users.",
              },
              {
                id: 2,
                subtitle: "End Users",
                img: innovativeContent_item2,
                content:
                  "Interact with specialized AI agents and collectives, built for Web3 and beyond.",
              },
              {
                id: 3,
                subtitle: "Ecosystem Partners",
                img: innovativeContent_item3,
                content:
                  "Monetize high-value datasets, AI models, and computational resources within a vibrant developer ecosystem.",
              },
              {
                id: 4,
                subtitle: "Community",
                img: innovativeContent_item4,
                content:
                  "Join a movement creating an open, collaborative ecosystem focused on clear rewards and community-driven innovation.",
              },
              {
                id: 5,
                subtitle: "AI Creators",
                img: innovativeContent_item5,
                content:
                  "Build, share, and own AI apps, then connect with millions of users on UniAgent AI Store.",
              },
              {
                id: 6,
                subtitle: "Other Projects",
                img: innovativeContent_item6,
                content:
                  "AI agents enhance UX in support, analytics, fraud detection, automation, and personalization.",
              },
            ].map((item: any, index: any) => (
              <>
                <div className="innovativeContent-item" key={index}>
                  <div className="innovativeContent-r">
                    <img src={item?.img} alt="" />
                  </div>
                  <div className="innovativeContent-box">
                    <div>{item?.subtitle}</div>
                    {item?.content}
                  </div>
                </div>
                {/* <div style={{ width: "32px" }}></div> */}
              </>
            ))}
          </div>
        </MoveFadeIn>
      </div>
    </div>
  );
}
