import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import blogs_item1 from "../../assets/image/blogs-item1.png";
import blogs_item2 from "../../assets/image/blogs-item2.png";
import blogs_item3 from "../../assets/image/blogs-item3.png";
import blogs_item4 from "../../assets/image/blogs-item4.png";
import blogs_item5 from "../../assets/image/blogs-item5.png";
import blogs_item6 from "../../assets/image/blogs-item6.png";
// import required modules
import { EffectCoverflow } from "swiper/modules";

import { isMobileDevice } from "../../utils";
import { useViewport } from "../viewportContext";
import MoveFadeIn from "./MoveFadeIn";

export default function Home() {
  const { width } = useViewport();

  const [selectKey, setSelectKey] = useState(0);

  return (
    <div className="blogs blogs-bg" id="Economy">
      <div className="w-1140">
        <MoveFadeIn>
          <div className="blogs-title">Blogs</div>
          <div className="blogs-subtitle">
            Check the latest updates on UniAgent Chain and its new discoveries,
            stay updated reading our blog and explore more about the products.
          </div>
        </MoveFadeIn>
        <MoveFadeIn>
          <div className="blogsContent">
            {[
              {
                id: 1,
                subtitle: "Developers",
                img: blogs_item1,
                content:
                  "UniAgent Joins Hugging Face’s ESP to Drive Onchain AI Innovation",
                link: "https://medium.com/@UniAgent/uniagent-joins-hugging-faces-esp-to-drive-onchain-ai-innovation-ad9a516774bc",
              },
              {
                id: 2,
                subtitle: "End Users",
                img: blogs_item2,
                content: "The Rise of the Agentic Economy",
                link: "https://medium.com/@UniAgent/the-rise-of-the-agentic-economy-28e81193ea6b",
              },
              {
                id: 3,
                subtitle: "Ecosystem Partners",
                img: blogs_item3,
                content:
                  "UniAgent Network: The Hub for Decentralized AI Agents",
                link: "https://medium.com/@UniAgent/uniagent-network-the-hub-for-decentralized-ai-agents-3d567f73cffe",
              },
              {
                id: 4,
                subtitle: "Community",
                img: blogs_item4,
                content:
                  "UniAgent Joins NVIDIA Inception Program to Accelerate Decentralized AI Innovation",
                link: "https://medium.com/@UniAgent/were-thrilled-to-announce-that-uniagent-has-officially-joined-nvidia-s-prestigious-inception-8d2fc6bae276",
              },
              {
                id: 5,
                subtitle: "AI Creators",
                img: blogs_item5,
                content:
                  "UniAgent Joins Google’s AI Startup Program to Accelerate Decentralized AI Innovation",
                link: "https://medium.com/@UniAgent/uniagent-joins-googles-ai-startup-program-to-accelerate-decentralized-ai-innovation-7d1b48ec6bf8",
              },
              {
                id: 6,
                subtitle: "Other Projects",
                img: blogs_item6,
                content:
                  "UniAgent Global Ambassador Program — CALLING FOR VOLUNTEERS",
                link: "https://medium.com/@UniAgent/uniagent-global-ambassador-program-calling-for-volunteers-19977937a100",
              },
            ].map((item: any, index: any) => (
              <>
                <div
                  className="blogsContent-item"
                  key={index}
                  onClick={() => {
                    window.open(item?.link);
                  }}
                >
                  <img src={item?.img} alt="" />
                  <div className="blogsContent-box">
                    {/* <div>
                      <span>#PRODUCT</span> <span>OCTOBER11</span>
                    </div> */}
                    {item?.content}
                  </div>
                </div>
                {/* <div style={{ width: "32px" }}></div> */}
              </>
            ))}
          </div>
        </MoveFadeIn>
      </div>
    </div>
  );
}
