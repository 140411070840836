import { useTranslation } from 'react-i18next';
import ReactEcharts from 'echarts-for-react';

export default function About() {
  const { t } = useTranslation();
  const getOption = () => {
    let option = {
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisTick: false,
        axisLine: {
          // 设置x轴的轴线不显示
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '30%'],
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: function (value: any, index: number) {
            return value;
            // return index === 0 ? '' : value; // 如果是第一个刻度（索引为0），返回空字符串，否则返回正常的值
          },
        },
      },
      series: [
        {
          type: 'line',
          smooth: 0.6,
          symbol: 'none',
          lineStyle: {
            color: '#5470C6',
            width: 4,
          },
          data: [
            ['10.10', 0],
            ['10.11', 60],
            ['10.12', 50],
            ['10.13', 80],
            ['10.14', 20],
          ],
        },
      ],
    };
    return option;
  };

  return (
    <div className='About'>
      {t('22')}--------
      <ReactEcharts option={getOption()} />
    </div>
  );
}
