import { useEffect, useState } from "react";
import logoIcon from "../../../assets/image/logo.png";
import openMenu from "../../../assets/image/h5/openMenu.svg";
import closeMenu from "../../../assets/image/h5/closeMenu.svg";
import dropdown from "../../../assets/image/h5/dropdown.svg";
import { useNavigate } from "react-router-dom";
import { scrollToAnchor } from "../../../utils";
//@ts-ignore
import bannerBg from "../../../assets/video/bannerBg.mp4";
import goTo from "../../../assets/image/goTo.svg";
import sm_banner_bg from "../../../assets/image/h5/sm_banner_bg.png";
import { linkBuyNFT, linkObj } from "./pc";
import { notification } from "antd";
import { useViewport } from "../../viewportContext";
import hotIcon from "../../../assets/image/hotIcon.png";

export default function Home() {
  const { width } = useViewport();
  const [showMask, setShowMask] = useState(false);
  const navigate = useNavigate();
  const [OpenList, setOpenList] = useState<any>([]);

  useEffect(() => {
    document.body.style.overflow = showMask ? "hidden" : "auto";
  }, [showMask]);
  // 释放记录列表
  const openFun = (type: any) => {
    let Arr: any = OpenList;
    if (Arr?.some((item: any) => Number(item) === Number(type))) {
      Arr = Arr?.filter((item: any) => Number(item) !== Number(type));
    } else {
      Arr = [...Arr, type];
    }

    setOpenList(Arr);
    console.log(Arr, "Arr");
  };
  return (
    <div className="h5-container">
      {/* {width >= 1440 && ( */}
      <div className={showMask ? "h5-header h5-header-open" : "h5-header "}>
        <img src={logoIcon} className="logoIcon" alt="" />
        <div style={{ flex: 1 }}></div>

        <div onClick={() => setShowMask(!showMask)}>
          <img
            src={openMenu}
            alt=""
            className="svg"
            style={{ display: !showMask ? "block" : "none" }}
          />
          <img
            src={closeMenu}
            alt=""
            className="svg"
            style={{ display: showMask ? "block" : "none" }}
          />
        </div>
      </div>
      {/* )} */}
      <div className="h5-header-main">
        <div className="h5-banner_bg">
          <div className="h5-banner">
            <div className="h5-banner-container">
              <div className="h5-banner-tips1">
                The Future Is AI Agentic…
                <br /> Join UniAgent And Own Your Share Of AI
              </div>
              <div className="h5-banner-tips2">
                With UniAgent, anyone can secure their share in the future of AI
                and autonomous agent ecosystems.
              </div>
              <div
                className="goTo"
                onClick={() => {
                  window.open(linkBuyNFT);
                  // return notification.open({
                  //   key: "500",
                  //   message: "Coming SOON",
                  // });
                }}
              >
                LAUNCH AI Node Key <img src={goTo} alt="" />
              </div>
            </div>
          </div>
          <video className="video" autoPlay loop muted playsInline>
            <source src={bannerBg} type="video/mp4" />
          </video>
          {/* <img src={sm_banner_bg} alt="" /> */}
        </div>
      </div>
      <div className="m_header">
        <div
          className="h5-header-mask"
          style={{ display: showMask ? "block" : "none" }}
        >
          <div className="scroll">
            <div
              className="h5-header-mask-li active"
              onClick={() => {
                scrollToAnchor("Homepage");
                setShowMask(false);
              }}
            >
              Home
            </div>
            <div
              className="h5-header-mask-li "
              onClick={() => {
                // scrollToAnchor("About");
                // setShowMask(false);
              }}
            >
              <div
                className="nav-item-hot"
                onClick={() => {
                  {
                    // setShowMask(false);
                    window.open(linkBuyNFT);
                    // return notification.open({
                    //   key: "500",
                    //   message: "Coming SOON",
                    // });
                  }
                }}
              >
                <img src={hotIcon} alt="" /> UniAgent AI Node Key
              </div>
            </div>
            <div
              className="h5-header-mask-li"
              onClick={() => {
                scrollToAnchor("Partners");
                setShowMask(false);
              }}
            >
              About
            </div>
            <div
              className="h5-header-mask-li"
              onClick={() => {
                scrollToAnchor("Technology");
                setShowMask(false);
              }}
            >
              Technology
            </div>
            <div
              className="h5-header-mask-li"
              onClick={() => {
                scrollToAnchor("UAC");
                setShowMask(false);
              }}
            >
              Super $UAC
            </div>

            <div
              className="h5-header-mask-li"
              onClick={() => {
                scrollToAnchor("Foundation");
                setShowMask(false);
              }}
            >
              Foundation
            </div>
            <div
              className="h5-header-mask-li"
              onClick={() => {
                scrollToAnchor("Vanta_Labs");
                setShowMask(false);
              }}
            >
              NexAI Labs
            </div>
            <div
              className="h5-header-mask-li"
              onClick={() => {
                openFun(0);
              }}
            >
              <div>
                Community{" "}
                <img
                  src={dropdown}
                  alt=""
                  className={
                    OpenList?.some((item1: any) => Number(item1) === Number(0))
                      ? "rotetaOpen"
                      : "rotetaClose"
                  }
                />
              </div>
              <div className="nav">
                {OpenList?.some(
                  (item1: any) => Number(item1) === Number(0)
                ) && (
                  <div>
                    <div
                      // onClick={() => {
                      //   dispatch(setCollapsedAction(true));
                      //   navigate("/View/AboutUs");
                      // }}
                      onClick={() => {
                        window.open(linkObj?.tw);
                      }}
                    >
                      Twitter
                    </div>
                    <div
                      // onClick={() => {
                      //   dispatch(setCollapsedAction(true));
                      //   navigate("/View/Invest");
                      // }}
                      onClick={() => {
                        window.open(linkObj?.tg);
                      }}
                    >
                      Telegram
                    </div>
                    <div
                      // onClick={() => {
                      //   dispatch(setCollapsedAction(true));
                      //   navigate("/View/ContractUs");
                      // }}
                      onClick={() => {
                        window.open(linkObj?.md);
                      }}
                    >
                      Medium
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="h5-header-mask-li"
              onClick={() => {
                openFun(1);
              }}
            >
              <div>
                Resources
                <img
                  src={dropdown}
                  alt=""
                  className={
                    OpenList?.some((item1: any) => Number(item1) === Number(1))
                      ? "rotetaOpen"
                      : "rotetaClose"
                  }
                />
              </div>
              <div className="nav">
                {OpenList?.some(
                  (item1: any) => Number(item1) === Number(1)
                ) && (
                  <div>
                    <div
                      // onClick={() => {
                      //   dispatch(setCollapsedAction(true));
                      //   navigate("/View/AboutUs");
                      // }}
                      onClick={() => {
                        window?.open(linkObj?.WhitePaper);
                      }}
                    >
                      Whitepaper
                    </div>
                    <div
                      // onClick={() => {
                      //   dispatch(setCollapsedAction(true));
                      //   navigate("/View/Invest");
                      // }}
                      onClick={() => {
                        window?.open(linkObj?.Gitbook);
                      }}
                    >
                      Gitbook
                    </div>
                    <div
                      // onClick={() => {
                      //   dispatch(setCollapsedAction(true));
                      //   navigate("/View/ContractUs");
                      // }}
                      onClick={() => {
                        if (!!linkObj?.Plan) {
                          window.open(linkObj?.Plan);
                        } else {
                          return notification.open({
                            key: "500",
                            message: "Coming SOON",
                          });
                        }
                      }}
                    >
                      Business Plan
                    </div>
                    <div
                      // onClick={() => {
                      //   dispatch(setCollapsedAction(true));
                      //   navigate("/View/ContractUs");
                      // }}
                      onClick={() => {
                        if (!!linkObj?.DeAI) {
                          window.open(linkObj?.DeAI);
                        } else {
                          return notification.open({
                            key: "500",
                            message: "Coming SOON",
                          });
                        }
                      }}
                    >
                      DeAI Lighting Plan
                    </div>
                    <div
                      // onClick={() => {
                      //   dispatch(setCollapsedAction(true));
                      //   navigate("/View/ContractUs");
                      // }}
                      onClick={() => {
                        if (!!linkObj?.Model) {
                          window.open(linkObj?.Model);
                        } else {
                          return notification.open({
                            key: "500",
                            message: "Coming SOON",
                          });
                        }
                      }}
                    >
                      Economic Model
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="h5-header-mask-li"
              onClick={() => {
                // scrollToAnchor("Roadmap");
                openFun(2);
              }}
            >
              <div>
                Language
                <img
                  src={dropdown}
                  alt=""
                  className={
                    OpenList?.some((item1: any) => Number(item1) === Number(2))
                      ? "rotetaOpen"
                      : "rotetaClose"
                  }
                />
              </div>
              <div className="nav">
                {OpenList?.some(
                  (item1: any) => Number(item1) === Number(2)
                ) && (
                  <div>
                    <div
                    // onClick={() => {
                    //   dispatch(setCollapsedAction(true));
                    //   navigate("/View/AboutUs");
                    // }}
                    >
                      English
                    </div>
                    {/* <div
                    onClick={() => {
                      dispatch(setCollapsedAction(true));
                      navigate("/View/Invest");
                    }}
                  >
                    简体中文
                  </div>
                  <div
                    onClick={() => {
                      dispatch(setCollapsedAction(true));
                      navigate("/View/ContractUs");
                    }}
                  >
                    日本語
                  </div>
                  <div
                    onClick={() => {
                      dispatch(setCollapsedAction(true));
                      navigate("/View/ContractUs");
                    }}
                  >
                    日本語
                  </div>
                  <div
                    onClick={() => {
                      dispatch(setCollapsedAction(true));
                      navigate("/View/ContractUs");
                    }}
                  >
                    한국인
                  </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
