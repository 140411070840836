import HeaderPc, { linkBuyNFT } from "./header/pc";
import HeaderH5 from "./header/h5";

import { isMobileDevice } from "../../utils";
import { useViewport } from "../viewportContext";
//@ts-ignore
import bannerBg from "../../assets/video/bannerBg.mp4";
// import bannerBg from "../../assets/image/bannerBg.png";
import goTo from "../../assets/image/goTo.svg";
import { notification } from "antd";
export default function Home() {
  const { width } = useViewport();
  return (
    <div id="Homepage">
      {width >= 1440 ? (
        <div className="pc-banner_bg">
          <div className="pc-banner">
            <HeaderPc></HeaderPc>
            <div className="pc-banner-container">
              <div className="pc-banner-tips1">
                The Future Is AI Agentic… <br />
                Join UniAgent And Own Your Share <br /> Of AI
              </div>
              <div className="pc-banner-tips2">
                With UniAgent, anyone can secure their share in the future of AI
                and autonomous agent ecosystems.
              </div>
              <div
                className="pc-banner-btn"
                onClick={() => {
                  window.open(linkBuyNFT);
                  // return notification.open({
                  //   key: "500",
                  //   message: "Coming SOON",
                  // });
                }}
              >
                UniAgent AI Node Key <img src={goTo} alt="" />
              </div>
            </div>
          </div>
          <video className="video" autoPlay loop muted playsInline>
            <source src={bannerBg} type="video/mp4" />
          </video>
          {/* <img className="video" src={bannerBg} alt="" /> */}
        </div>
      ) : (
        <HeaderH5></HeaderH5>
      )}
    </div>
  );
}
