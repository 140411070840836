import React, { ReactElement, useEffect, useRef, useState } from "react";
import clsx from "clsx";

import styles from "./moveFadeIn.module.scss";

interface Props {
  children: ReactElement | ReactElement[];
}

export default function ({ children }: Props): React.ReactElement {
  const eleRef: any = useRef();
  const [triggerFadeIn, setTriggerFadeIn] = useState(false);

  useEffect(() => {
    let observer: IntersectionObserver;
    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.map((entry) => {
        // 当元素和viewport相交时，添加类名选择器，触发对应的animation
        if (entry.isIntersecting && entry.target === eleRef.current) {
          //   debugger;
          setTriggerFadeIn(true);
          // 释放掉observer，减少性能负担
          observer.disconnect();
        }
      });
    };

    const options = {
      rootMargin: "-100px", // 缩小viewport
    };

    observer = new IntersectionObserver(observerCallback, options);
    observer.observe(eleRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={eleRef}
      // 这里用到clsx，当 triggerFadeIn 为 true 时，styles.fadeIn 会生效
      className={clsx(styles.wrap, triggerFadeIn && styles.fadeIn)}
    >
      {children}
    </div>
  );
}
