import { useState } from "react";
import about_img1 from "../../assets/image/about_img1.png";
import sm_content_img1 from "../../assets/image/sm_content_img1.png";
import sm_content_img2 from "../../assets/image/sm_content_img2.png";
import sm_content_img3 from "../../assets/image/sm_content_img3.png";
import industry_img1 from "../../assets/image/industry_img1.svg";
import industry_img2 from "../../assets/image/industry_img2.svg";
import industry_img3 from "../../assets/image/industry_img3.svg";
import industry_img4 from "../../assets/image/industry_img4.svg";
import industry_img5 from "../../assets/image/industry_img5.svg";
import industry_img6 from "../../assets/image/industry_img6.svg";
import industry_img7 from "../../assets/image/industry_img7.svg";
import industry_img8 from "../../assets/image/industry_img8.svg";
import industry_img9 from "../../assets/image/industry_img9.svg";
import industry_img10 from "../../assets/image/industry_img10.svg";
import industry_img11 from "../../assets/image/industry_img11.svg";
import industry_img12 from "../../assets/image/industry_img12.svg";
import industry_img13 from "../../assets/image/industry_img13.svg";
import industry_img14 from "../../assets/image/industry_img14.svg";
import industry_img15 from "../../assets/image/industry_img15.svg";
import industry_img16 from "../../assets/image/industry_img16.svg";
import industry_img17 from "../../assets/image/industry_img17.svg";
import industry_img18 from "../../assets/image/industry_img18.svg";
import tgIcon from "../../assets/image/tgIcon.png";
import xIcon from "../../assets/image/xIcon.png";

import copy from "copy-to-clipboard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useViewport } from "../viewportContext";
// @ts-ignore
import about_video from "../../assets/video/about_video.mp4";

// @ts-ignore
// import Animation_4 from "../../assets/image/Animation_4.png";
import Animation_4 from "../../assets/video/Animation-4.mp4";
// @ts-ignore
// import Animation_1 from "../../assets/image/Animation_1.png";
import Animation_1 from "../../assets/video/Animation-1.mp4";
// @ts-ignore

// import Animation_3 from "../../assets/image/Animation_3.png";
import Animation_3 from "../../assets/video/Animation-3.mp4";
import MoveFadeIn from "./MoveFadeIn";
import { linkObj } from "./header/pc";
import Marquee from "react-fast-marquee";

const Imgs: any = [
  industry_img1,
  industry_img2,
  industry_img3,
  industry_img4,
  industry_img5,
  industry_img6,
  industry_img7,
  industry_img8,
  industry_img9,
  industry_img10,
  industry_img11,
  industry_img12,
  industry_img13,
  industry_img14,
  industry_img15,
  industry_img16,
  industry_img17,
  industry_img18,
];

export default function Home() {
  const { width } = useViewport();
  function coppyUrl(url: string) {
    copy(url);
    toast.success("Copy Successful", {
      hideProgressBar: true,
      autoClose: 1000,
    });
  }

  const [loading, setLoading] = useState(false);
  const [showMask, setShowMask] = useState(false);
  const [name, setName] = useState("");
  const [userAddress, setUserAddress] = useState("");

  return (
    <div className="about" id="About">
      <div className="about-container">
        <MoveFadeIn>
          <div className="aboutItem aboutItem1">
            <div className="title">
              Be Part of the <span> AI Revolution </span>
            </div>
            <div className="content">
              <div
                className="PartItem"
                onClick={() => {
                  window.open(linkObj?.tg);
                }}
              >
                <img src={tgIcon} alt="" /> Telegram
              </div>
              <div className="dv"></div>
              <div
                className="PartItem"
                onClick={() => {
                  window.open(linkObj?.tw);
                }}
              >
                <img src={xIcon} alt="" /> Twitter
              </div>
            </div>
          </div>
        </MoveFadeIn>
        <MoveFadeIn>
          <div className="aboutItem aboutItem1">
            <div className="title">
              {/* About <span> Vanta </span> */}
              Introduce <span> UniAgent’s </span> Decentralized Internet of
              Agents
            </div>
            <div className="content">
              <div className="contentLeft">
                UniAgent is the first on-chain multi-agent project designed to
                create a decentralized, permissionless, and secure ecosystem for
                autonomous AI agents to collaborate and coordinate. Backed by
                the DeAI Foundation, UniAgent aims to build a future where
                specialized, vertical AI agents are integrated across
                industries, enhancing human creativity and productivity. This
                innovative platform fosters a collaborative relationship between
                humans and AI, positioning AI as a supportive ally rather than a
                threat. Additionally, UniAgent introduces a novel AI-powered
                currency, designed to drive the growth of a decentralized Agent
                Economy within the blockchain, ensuring long-term sustainability
                and value exchange across the ecosystem.
              </div>

              <div className="contentRight">
                {/* <img src={about_img1} alt="" /> */}
                <video className="video" autoPlay loop muted playsInline>
                  <source src={about_video} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </MoveFadeIn>

        <div className="aboutItem aboutItem2">
          <MoveFadeIn>
            <div className="title" style={{ maxWidth: "100%" }}>
              Unlocking The Next <span> Trillion-Dollar Trusted AI </span> Asset
              Network By Bringing Large-Scale AI Applications On-Chain
            </div>
          </MoveFadeIn>
          <div className="content">
            <MoveFadeIn>
              <div className="produce">
                The fastest modular AI blockchain, delivering unmatched
                infrastructure to scale both Web3 and AI, future-proofing the
                growing demands of AI.
              </div>
            </MoveFadeIn>
            <MoveFadeIn>
              {width >= 1024 ? (
                <div className="sm-content1">
                  <div className="sm-content1-left">
                    <div className="subtitle">UniAgent Chain</div>
                    {/* <div className="tag">LAUNCHED</div> */}
                    UniAgent Chain is a high-performance Layer 1 blockchain
                    supporting both AI and Web3, with 10,000 transactions per
                    second. EVM-compatible and optimized for low hardware
                    requirements, it offers single-slot finality for scalable AI
                    and decentralized apps. With fully on-chain AI operations,
                    UniAgent Chain ensures transparency, security, and
                    verifiability for the next generation of decentralized AI
                    networks.
                  </div>
                  <div className="sm-content1-right">
                    {/* <img src={sm_content_img1} alt="" /> */}
                    <video className="video" autoPlay loop muted playsInline>
                      <source src={Animation_1} type="video/mp4" />
                    </video>
                    {/* <img className="video" src={Animation_1} alt="" /> */}
                  </div>
                </div>
              ) : (
                <div className="sm-content1">
                  <div className="sm-content1-right">
                    {/* <img src={sm_content_img1} alt="" /> */}
                    <video className="video" autoPlay loop muted playsInline>
                      <source src={Animation_1} type="video/mp4" />
                    </video>
                    {/* <img className="video" src={Animation_1} alt="" /> */}
                  </div>
                  <div className="sm-content1-left">
                    <div className="subtitle">UniAgent Chain</div>
                    {/* <div className="tag">LAUNCHED</div> */}
                    UniAgent Chain is a high-performance Layer 1 blockchain
                    supporting both AI and Web3, with 10,000 transactions per
                    second. EVM-compatible and optimized for low hardware
                    requirements, it offers single-slot finality for scalable AI
                    and decentralized apps. With fully on-chain AI operations,
                    UniAgent Chain ensures transparency, security, and
                    verifiability for the next generation of decentralized AI
                    networks.
                  </div>
                </div>
              )}
            </MoveFadeIn>
            <MoveFadeIn>
              <div className="sm-content1 sm-content2">
                <div className="sm-content1-right">
                  {/* <img className="video" src={Animation_3} alt="" /> */}
                  <video className="video" autoPlay loop muted playsInline>
                    <source src={Animation_3} type="video/mp4" />
                  </video>
                </div>
                <div className="sm-content1-left">
                  <div className="subtitle">UniAgent DA++</div>
                  {/* <div className="tag">2025 H2</div> */}
                  UniAgent DA++ is the first AI-native data availability (DA)
                  layer, leveraging erasure coding and zero-knowledge proof
                  (ZKP) technology to deliver high-performance, infinitely
                  scalable storage and verification for on-chain AI
                  applications. Designed to bridge the gap between Web3 and
                  Web2, UniAgent DA++ enables Web3 platforms to achieve
                  Web2-level performance and cost efficiency, providing a
                  programmable and scalable data availability solution for
                  end-users.
                </div>
              </div>
            </MoveFadeIn>
            <MoveFadeIn>
              {width >= 1024 ? (
                <div className="sm-content1">
                  <div className="sm-content1-left">
                    <div className="subtitle">UniAgent AIStorage</div>
                    {/* <div className="tag">2027 H1</div> */}
                    UniAgent AIStorage is a highly available decentralized
                    storage layer designed to support exabyte-level capacity and
                    scale across millions of nodes. It enables secure storage of
                    models, datasets, and training data, offering a reliable
                    solution for the growing needs of AI applications.
                  </div>
                  <div className="sm-content1-right">
                    {/* <img src={Animation_4} className="video" alt="" /> */}
                    <video className="video" autoPlay loop muted playsInline>
                      <source src={Animation_4} type="video/mp4" />
                    </video>
                  </div>
                </div>
              ) : (
                <div className="sm-content1">
                  <div className="sm-content1-right">
                    {/* <img src={Animation_4} className="video" alt="" /> */}
                    <video className="video" autoPlay loop muted playsInline>
                      <source src={Animation_4} type="video/mp4" />
                    </video>
                  </div>
                  <div className="sm-content1-left">
                    <div className="subtitle">UniAgent AIStorage</div>
                    {/* <div className="tag">2027 H1</div> */}
                    UniAgent AIStorage is a highly available decentralized
                    storage layer designed to support exabyte-level capacity and
                    scale across millions of nodes. It enables secure storage of
                    models, datasets, and training data, offering a reliable
                    solution for the growing needs of AI applications.
                  </div>
                </div>
              )}
            </MoveFadeIn>
            <MoveFadeIn>
              <div className="industrys" id="Partners">
                <div className="industry-title">
                  UniAgent is backed by the best in the industry
                </div>
                <div className="industry-content">
                  <Marquee>
                    {Imgs?.slice(0, 6)?.map((item: any, index: any) => (
                      <>
                        <div key={index} className="industry-item">
                          <img src={item} alt="" />
                        </div>
                        <div className="gap"></div>
                      </>
                    ))}
                  </Marquee>
                </div>
                <div className="industry-content">
                  <Marquee>
                    {Imgs?.slice(6, 12)?.map((item: any, index: any) => (
                      <>
                        <div key={index} className="industry-item">
                          <img src={item} alt="" />
                        </div>
                        <div className="gap"></div>
                      </>
                    ))}
                  </Marquee>
                </div>
                <div className="industry-content">
                  <Marquee>
                    {Imgs?.slice(12, 18)?.map((item: any, index: any) => (
                      <>
                        <div key={index} className="industry-item">
                          <img src={item} alt="" />
                        </div>
                        <div className="gap"></div>
                      </>
                    ))}
                  </Marquee>
                </div>
              </div>
            </MoveFadeIn>
          </div>
        </div>
      </div>
    </div>
  );
}
