import React from "react";

import PcBanner from "../components/home/PcBanner";
import Brand from "../components/home/brand";
import AboutUs from "../components/home/aboutUs";
import Encouragement from "../components/home/Encouragement";
import CoreFeatures from "../components/home/CoreFeatures";
import Innovative from "../components/home/Innovative";
import Ecosystem from "../components/home/Ecosystem";
import Footer from "../components/home/Footer";
import Blogs from "../components/home/Blogs";
// import DragonSwiper from "../components/home/DragonSwiper";

function App() {
  return (
    <div className="home">
      {/* <DragonSwiper></DragonSwiper> */}
      <PcBanner></PcBanner>

      <AboutUs></AboutUs>
      <CoreFeatures></CoreFeatures>
      <Innovative></Innovative>
      <Ecosystem></Ecosystem>

      <Encouragement></Encouragement>
      <Blogs></Blogs>

      <Footer></Footer>
    </div>
  );
}

export default App;
