import {
  Route,
  BrowserRouter as Router,
  Routes,
  Outlet,
} from "react-router-dom";

import Home from "../page/index";
import About from "../page/about";
import ViewportProvider from "../components/viewportContext";

export default function AppRouter() {
  return (
    <ViewportProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Outlet />}>
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
            </Route>
          </Routes>
        </Router>
      </div>
    </ViewportProvider>
  );
}
