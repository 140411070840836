import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
// import required modules
import { EffectCoverflow } from "swiper/modules";

import { isMobileDevice } from "../../utils";
import { useViewport } from "../viewportContext";
import assetImg1 from "../../assets/image/assetImg1.png";
import assetImg2 from "../../assets/image/assetImg2.png";
import assetImg3 from "../../assets/image/assetImg3.png";
import assetsImg from "../../assets/image/assetsImg.png";
import fundimg1 from "../../assets/image/fundimg1.png";
import fundimg2 from "../../assets/image/fundimg2.png";
import fundimg3 from "../../assets/image/fundimg3.png";
import fundimg4 from "../../assets/image/fundimg4.png";
//@ts-ignore
import Globe_Animation from "../../assets/video/global.gif";
// import Globe_Animation from "../../assets/image/Globe_Animation.png";
//@ts-ignore
// @ts-ignore
import Animation_2 from "../../assets/video/Animation_2.mp4";
// import Animation_2 from "../../assets/image/Animation_2.png";
import MoveFadeIn from "./MoveFadeIn";
export default function Home() {
  const { width } = useViewport();

  const [selectKey, setSelectKey] = useState(0);

  return (
    <div className="ecosystem ecosystem-bg">
      <div className="w-1140">
        <MoveFadeIn>
          <div className="ecosystem-title">
            {" "}
            <span>UniAgent</span> RoadMap
          </div>

          <div className="ecosystemContent">
            <div className="ecosystemContent-item">
              <div className="subtitle">2024 Q4</div>
              <div className="p">
                <ul>
                  <li>UniAgent AI Node Key Pre-sale Launch</li>
                  <li>Launch the Global UniAgent AI Community</li>
                  <li>Testnet launch for developer validation</li>
                  <li>Secure 10+ strategic partnerships</li>
                  <li>Season 1 of Community Incentives</li>
                </ul>
              </div>
            </div>
            <div className="gap"></div>
            <div className="ecosystemContent-item">
              <div className="subtitle">2025 H1</div>
              <div className="p">
                <ul>
                  <li>UniAgent Mainnet Launch</li>
                  <li>AI Node Key Mining Activation</li>
                  <li>$UAC Trading Goes Live</li>
                  <li>PoS Consensus Phase I</li>
                  <li>IAO Mechanism Phase I</li>
                </ul>
              </div>
            </div>
            <div className="gap"></div>

            <div className="ecosystemContent-item">
              <div className="subtitle">2025 H2</div>
              <div className="p">
                <ul>
                  <li>Agent Registration and Minting</li>
                  <li>$UAC Listed on More CEXs</li>
                  <li>Expanded $UAC Utility Scenarios</li>
                  <li>Agent Hub: Discover & Integrate Agents</li>
                  <li>Community Staking to Secure Agents</li>
                </ul>
              </div>
            </div>
          </div>
        </MoveFadeIn>
        <MoveFadeIn>
          <div className="ecosystem-title" id="UAC">
            <span>$UAC's</span> Potential As a Super Asset Like ETH
          </div>
          <div className="tip">
            As UniAgent grows, $UAC’s value rises, fueled by a sustainable
            economic model.
          </div>
          <div className="ecosystem-assets">
            <div className="ecosystem-assets-left">
              {[
                {
                  img: assetImg1,
                  title: "Capital Asset",
                  content:
                    'UAC holders can stake tokens for rewards, providing a "cash flow" that gives UAC discounted value.',
                },
                {
                  img: assetImg2,
                  title: "Store of Value",
                  content:
                    "Help build out the network and Earn KONE tokens by operating our radio Gateways as a Gatekeeper Node.",
                },
                {
                  img: assetImg3,
                  title: "Consumable Asset",
                  content:
                    "UAC acts as the base transaction fee, and as its supply decreases with use, its scarcity and value increase.",
                },
              ].map((item: any, index: any) => (
                <div className="item" key={index}>
                  <div className="item-top">
                    <img src={item?.img} alt="" />
                    {item?.title}
                  </div>
                  <div className="item-bottom">{item?.content}</div>
                </div>
              ))}
            </div>
            <div className="ecosystem-assets-right">
              {/* <img className="video" src={Animation_2} alt="" /> */}
              <video className="video" autoPlay loop muted playsInline>
                <source src={Animation_2} type="video/mp4" />
              </video>
            </div>
          </div>
        </MoveFadeIn>
        <MoveFadeIn>
          <div className="ecosystem-title" id="Foundation">
            <span>UniAgent Foundation</span> Pioneers The First Decentralized AI
            Alliance (DeASI)
          </div>
          <div className="tip">
            Since 2017, the core team of UniAgent Foundation has been deeply
            involved in the Crypto and AI fields, having invested in renowned
            projects such as Ethereum, Filecoin, Polkadot, and Anthropic. To
            address the potential risk of AI monopolization by tech giants,
            UniAgent Foundation has launched the first decentralized AI alliance
            (DeASI), aimed at accelerating the widespread adoption of
            decentralized AI Agents. Currently, multiple key players, including
            Filecoin Foundation, Polygon, Hypercycle, and Base, have confirmed
            their participation in DeASI, providing strong momentum for the
            development of the UniAgent ecosystem.
          </div>
          <div className="fund-content">
            <div className="fund-item">
              <img src={fundimg1} alt="" />
            </div>
            <div className="fund-item">
              <img src={fundimg2} alt="" />
            </div>
            <div className="fund-item">
              <img src={fundimg3} alt="" />
            </div>
            <div className="fund-item">
              <img src={fundimg4} alt="" />
            </div>
          </div>
          <div className="fund-video">
            {/* <video className="video" autoPlay loop muted playsInline>
              <source src={Globe_Animation} type="video/mp4" />
            </video> */}
            <img className="video" src={Globe_Animation} alt="" />
          </div>
        </MoveFadeIn>
      </div>
    </div>
  );
}
