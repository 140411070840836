import x from "../../assets/image/x.svg";
import tg from "../../assets/image/tg.svg";
import footerLogo from "../../assets/image/footerLogo.png";
import { link } from "fs";
import { linkObj } from "./header/pc";
import { notification } from "antd";

export default function Home() {
  return (
    <div className="footers">
      <div className="w-1140 footers-box">
        <div className="footers-top">
          <div className="footers-links">
            <div className="footerImg">
              {" "}
              <img src={footerLogo} alt="" />
              UniAgent
            </div>
            <div className="tip">The Decentralized AI Blockchain Platform</div>
            <div className="contracts">
              <img
                src={x}
                alt=""
                onClick={() => {
                  window.open(linkObj?.tw);
                }}
              />
              <img
                src={tg}
                alt=""
                onClick={() => {
                  window.open(linkObj?.tg);
                }}
              />
            </div>
          </div>
          <div className="navs">
            <div className="nav-items">
              <div>COMMUNITY</div>
              <div
                onClick={() => {
                  window.open(linkObj?.tg);
                }}
              >
                Telegram
              </div>
              <div
                onClick={() => {
                  window.open(linkObj?.tw);
                }}
              >
                Twitter
              </div>
              <div
                onClick={() => {
                  window.open(linkObj?.md);
                }}
              >
                Medium
              </div>
            </div>
            <div className="nav-items">
              <div>DOCS</div>
              <div
                onClick={() => {
                  window.open(linkObj?.WhitePaper);
                }}
              >
                Whitepaper
              </div>
              <div
                onClick={() => {
                  window.open(linkObj?.Gitbook);
                }}
              >
                Gitbook
              </div>
              <div
                onClick={() => {
                  if (!!linkObj?.Plan) {
                    window.open(linkObj?.Plan);
                  } else {
                    return notification.open({
                      key: "500",
                      message: "Coming SOON",
                    });
                  }
                }}
              >
                Business Plan
              </div>
              <div
                onClick={() => {
                  if (!!linkObj?.DeAI) {
                    window.open(linkObj?.DeAI);
                  } else {
                    return notification.open({
                      key: "500",
                      message: "Coming SOON",
                    });
                  }
                }}
              >
                DeAI Lighting Plan
              </div>
              <div
                onClick={() => {
                  if (!!linkObj?.Model) {
                    window.open(linkObj?.Model);
                  } else {
                    return notification.open({
                      key: "500",
                      message: "Coming SOON",
                    });
                  }
                }}
              >
                Economic Model
              </div>
            </div>
            <div className="nav-items">
              <div>COMPANY</div>
              <div>About</div>
              <div>Foundation</div>
              <div>Technology</div>
              <div>NexAI Labs</div>
            </div>
          </div>
        </div>
        <div className="footers-bottom">
          © 2024 NexAI Labs. | All rights reserved
        </div>
      </div>
    </div>
  );
}
